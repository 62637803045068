<template>
  <div class="splash-cont animated">
    <div class="splash-content animated">
      <img class="spac-logo" src="/img/SPAC_LOGO_blacknwhite.png" />
      <!-- <p class="spac-t animated animate_slow">
        South Philippine <br />
        Adventist College
      </p> -->
      <div class="cursive c-1 animated">Experience</div>
      <h1 class="title">
        TRANSFORMATIVE
        <div class="aurora">
          <div class="aurora__item"></div>
          <div class="aurora__item"></div>
          <div class="aurora__item"></div>
          <div class="aurora__item"></div>
        </div>
      </h1>
      <div class="cursive c-2 animated">Christian Education!</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SplashScreen",
  mounted() {
    const time = this.$route.name === "home" ? 8000 : 6000;
    $("body").addClass("no-scroll");
    $(".splash-content").addClass("zoomIn");
    $(".c-1").addClass("fadeInLeft");
    $(".c-2").addClass("fadeInRight");
    $(".splash-content .spac-logo").addClass("fadeIn");
    $(".splash-content .spac-t").addClass("fadeIn");
    $(".splash-content .sub-1").addClass("fadeIn");
    setTimeout(() => {
      $(".spac-logo").addClass("show");
    }, 4000);
    setTimeout(() => {
      $("body").removeClass("no-scroll");
    }, time + 1000);
    setTimeout(() => {
      $(".splash-content").removeClass("zoomIn").addClass("fadeOutUp");
      $(".splash-cont").addClass("fadeOut");
      $(".splash-cont").addClass("fadeOut");
    }, time);
  },
  beforeDestroy() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.splash-content {
  animation-duration: 4s;
  position: relative;
}
.splash-cont {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
img.spac-logo {
  width: 150px;
  transition: 1s ease;
  opacity: 0;
}
img.spac-logo.show {
  opacity: 1;
}
.spac-t {
  font-family: "Trajan Pro";
  font-size: 2.2rem;
  background-color: rgb(255, 208, 0);
  background-image: -webkit-linear-gradient(
    280deg,
    rgb(255, 213, 0) 0%,
    rgb(255, 98, 0) 100%
  );
  background-color: #f3ec78;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}
.c-1 {
  margin-bottom: -50px;
  font-size: clamp(2rem, 8vw, 5rem);
  margin-right: 50%;
  animation-duration: 2s;
}
.c-2 {
  margin-top: -50px;
  margin-left: 10%;
  font-size: clamp(2rem, 8vw, 6rem);
  animation-duration: 3s;
  width: 100%;
}
.cursive {
  font-family: "gastony";
  color: black;
  z-index: 9;
  position: relative;
}

@media screen and (max-width: 800px) {
  .c-1 {
    margin-bottom: -30px;
  }
  .c-2 {
    margin-top: -30px;
  }
  img.spac-logo {
    width: 120px;
  }
}

@media screen and (max-width: 520px) {
  .splash-content {
    transform: scale(0.8);
  }
}

.content {
  text-align: center;
}

.title {
  font-size: clamp(3rem, 8vw, 6rem);
  font-weight: 800;
  font-family: "Tommy Black", "LEMON MILK", "DM Sans", Arial, sans-serif;
  letter-spacing: clamp(-1.75px, -0.25vw, -3.5px);
  position: relative;
  overflow: hidden;
  background: #fff;
  border: 5px solid white;
  color: #ff7b00;
  margin: 0;
}

.subtitle {
}

.aurora {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  mix-blend-mode: lighten;
  pointer-events: none;
  overflow: hidden;
}

.aurora__item {
  overflow: hidden;
  position: absolute;
  width: 60vw;
  height: 60vw;
  background-color: #ffe100;
  border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
  filter: blur(1rem);
  mix-blend-mode: overlay;
}

.aurora__item:nth-of-type(1) {
  top: -50%;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-1 12s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(2) {
  background-color: #ff8400;
  right: 0;
  top: 0;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-2 12s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(3) {
  background-color: #ff3c00;
  left: 0;
  bottom: 0;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-3 8s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(4) {
  background-color: #ffe100;
  right: 0;
  bottom: -50%;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-4 24s ease-in-out infinite alternate;
}

@keyframes aurora-1 {
  0% {
    top: 0;
    right: 0;
  }

  50% {
    top: 100%;
    right: 75%;
  }

  75% {
    top: 100%;
    right: 25%;
  }

  100% {
    top: 0;
    right: 0;
  }
}

@keyframes aurora-2 {
  0% {
    top: -50%;
    left: 0%;
  }

  60% {
    top: 100%;
    left: 75%;
  }

  85% {
    top: 100%;
    left: 25%;
  }

  100% {
    top: -50%;
    left: 0%;
  }
}

@keyframes aurora-3 {
  0% {
    bottom: 0;
    left: 0;
  }

  40% {
    bottom: 100%;
    left: 75%;
  }

  65% {
    bottom: 40%;
    left: 50%;
  }

  100% {
    bottom: 0;
    left: 0;
  }
}

@keyframes aurora-4 {
  0% {
    bottom: -50%;
    right: 0;
  }

  50% {
    bottom: 0%;
    right: 40%;
  }

  90% {
    bottom: 50%;
    right: 25%;
  }

  100% {
    bottom: -50%;
    right: 0;
  }
}

@keyframes aurora-border {
  0% {
    border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
  }

  25% {
    border-radius: 47% 29% 39% 49% / 61% 19% 66% 26%;
  }

  50% {
    border-radius: 57% 23% 47% 72% / 63% 17% 66% 33%;
  }

  75% {
    border-radius: 28% 49% 29% 100% / 93% 20% 64% 25%;
  }

  100% {
    border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
  }
}
</style>
