<template>
  <div class="nav-bar-item-cont">
    <a
      v-if="item.type === 'external'"
      :class="navClass"
      class="nav-bar-item"
      :href="item.link"
      target="_blank"
    >
      {{ item.title }}</a
    >
    <router-link
      v-else
      class="nav-bar-item"
      :class="navClass"
      :to="{ name: item.link }"
    >
      {{ item.title }}
    </router-link>
    <div class="sub-menu" :class="`sub-menu-${item.link}`" v-if="item.sub_menu">
      <ul>
        <li v-for="(subItem, key) in item.sub_menu" :key="key">
          <router-link
            :to="{
              name: subItem.link,
              params: subItem.params,
            }"
          >
            {{ subItem.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavItem",
  props: ["item", "variant"],
  mounted() {
    // $(`.nav-bar-item-${this.item.link}`).on("hover", function () {
    //   $(".sub-menu").removeClass("visible");
    //   $(`sub-menu-${this.item.link}`).removeClass("visible");
    // });
    // document.addEventListener("click", (event) => {
    //   $("ul.main-menu").removeClass("has-active");
    //   $("li.parent-li")
    //     .not("#" + $(event.target).closest("li.parent-li").attr("id"))
    //     .removeClass("active");
    //   if (event.target.closest(".sub-menu"))
    //     $("li.parent-li").removeClass("active");
    // });
  },
  computed: {
    navClass() {
      let isActive = this.$route.name === this.item.link ? "active" : "";
      let hasSubMenu = this.item.sub_menu ? "has-sub-menu" : "";
      return `${this.item.addClass} ${this.variant} nav-bar-item-${this.item.link} ${isActive} ${hasSubMenu}`;
    },
  },
};
</script>

<style scoped>
.link-1.active {
  color: rgb(255, 174, 0) !important;
}
.link-1 {
  color: rgb(255, 208, 0);
  display: block;
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 300;
  font-size: 0.8rem;
}
.lightTopBG .link-1 {
  color: rgb(235 154 0);
  font-weight: 400;
}
.lightTopBG .link-2 {
  color: #3a3a3a;
}
.ais {
  transition: 0.4s ease;
  font-weight: 500;
  color: var(--orange) !important;
}
.ais:hover {
  transform: scale(1.05);
}

.mobile-nav .ais {
  color: rgb(0, 72, 255) !important;
}

.fixed .link-1 {
  color: rgb(255, 255, 255);
}

.link-2 {
  display: block;
  color: rgb(255, 255, 255);
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 400;
  font-size: 1.1rem;
}
.link-2.active {
  color: rgb(255, 174, 0) !important;
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 500;
}
.fixed .link-2 {
  color: rgb(42, 42, 42);
}
a.link-1,
a.link-2 {
  text-decoration: none !important;
  transition: 0.4s ease;
  cursor: pointer;
}
a.link-1:hover,
a.link-2:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.sub-menu {
  position: absolute;
  text-align: left;
  background: var(--orange);
  padding: 10px;
  border-radius: 16px;
  box-shadow: 5px 8px 12px rgb(0 0 0 / 20%);
  transition: 0.2s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  transform: translateY(20px);
}
.nav-bar-item-cont:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: unset;
}
.nav-bar-item {
  padding: 0px 10px;
  border-radius: 8px 8px 0px 0px;
  border: none;
}
.nav-bar-item-cont:hover .has-sub-menu {
  background: rgb(255 208 0 / 71%);
  color: rgb(46, 44, 33) !important;
}
.sub-menu li {
  min-width: 300px;
}
.sub-menu li a {
  font-family: "LEMON MILK";
  font-size: 1rem;
  color: rgb(46, 44, 33);
  text-decoration: none !important;
  padding: 10px 10px;
  width: 100%;
  border-radius: 12px;
  transition: 0.2s ease;
  cursor: pointer;
  margin-left: 0px !important;
  display: block;
  margin-right: 0px !important;
}
.sub-menu li a:hover {
  background: rgba(46, 44, 33, 0.129);
}
.sub-menu ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
@media (max-width: 769px) {
  .sub-menu {
    display: none !important;
  }
}
</style>
