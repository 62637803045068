<template>
  <div class="accreditors-cont">
    <div class="row">
      <div
        class="col-md-4 col-12 animate__delay-2s"
        v-animate-onscroll="'animated fadeInUp'"
      >
        <img src="/img/aaa.png" />
        <p class="title">Accredited by</p>
        <p class="info">
          International Board of Education of the Adventist Accrediting
          Association
        </p>
      </div>
      <div
        class="col-md-4 col-12 animate__delay-3s"
        v-animate-onscroll="'animated fadeInUp'"
      >
        <img src="/img/acscu.png" />
        <p class="title">Accredited by</p>
        <p class="info">
          Association of Christian Schools, Colleges, and Universities
          Accrediting Council, Incorporated
        </p>
      </div>
      <div
        class="col-md-4 col-12 animate__delay-4s"
        v-animate-onscroll="'animated fadeInUp'"
      >
        <img src="/img/ched.png" />
        <p class="title">Recognized by</p>
        <p class="info">Commission on Higher Education (CHED)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccreditationsPanel",
};
</script>

<style scoped>
.accreditors-cont {
  width: 100%;
  padding: 5%;
}
.title {
  font-family: "LEMON MILK", "Helvetica", san-serif;
  color: var(--orange);
}
.info {
  font-size: 1.2rem;
  color: rgb(152, 152, 152);
  font-family: "Avenir roman";
}
.info,
.title {
  font-weight: 500;
}
img {
  margin-bottom: 60px;
  height: 180px;
}
</style>
