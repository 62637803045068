<template>
  <div class="home-panel-2">
    <div class="img-cont">
      <!-- <img src="/img/img1.jpg" /> -->
    </div>
    <div class="orange-bar-v"></div>
    <div class="msg">
      SPAC is the best choice for those who seek scientific knowledge or
      literary acquirements in an environment where nature becomes
      uncompromising. But above intellectual acquirements, the school strives to
      build character in a student. Our curriculum is crafted to deliver the
      balance of four dimensions in the education of the young – mental,
      physical, social and spiritual under the auspices of our committed and
      dedicated molders of dreams.
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePanel2",
  components: {},
};
</script>

<style scoped>
.home-panel-2 {
  height: 900px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  background-color: #edecf4;
}
.img-cont {
  width: 45%;
  background-image: url("~@/assets/img/img1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  min-height: 600px;
}
img {
  width: 100%;
}
.msg {
  text-align: left;
  padding: 0rem 5rem 0rem 0rem;
  width: 45%;
  font-size: 1.3rem;
  font-family: "Avenir roman";
  line-height: 3rem;
}
@media (max-width: 769px) {
  .home-panel-2 {
    flex-direction: column;
    height: unset;
  }
  .img-cont {
    width: 100%;
  }
  .msg {
    width: 100%;
    padding: 3rem;
    text-align: center;
  }
  .orange-bar-v {
    height: 5px;
    width: 150px;
    margin-top: 50px;
  }
}
</style>
