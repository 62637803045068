<template>
  <div class="news-card">
    <div class="img" :style="bgImg">
      <!-- <img :src="article.feature_image" /> -->
    </div>
    <div class="news-orange-bar"></div>
    <div class="info">
      <p class="title">{{ article.title }}</p>
      <p class="subtitle">{{ article.subtitle }}</p>
      <div class="article-info">
        <span> <i class="icon-feather-calendar"></i> {{ articleDate }} </span>
        <span>
          <template v-if="article.author">
            <i class="icon-feather-user"></i> {{ article.author.name }}
          </template>
          <template v-if="article.author2">
            <i class="icon-feather-user" v-if="!article.author"></i>
            <template v-if="article.author"> | </template>
            {{ article.author2 }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: ["article"],
  computed: {
    bgImg() {
      if (!this.article.cover) return null;
      return `background-image: url('${this.backendServer}${this.article.cover.formats.medium.url}')`;
    },
    articleDate() {
      if (!this.article) return null;
      if (!this.article.date) return null;
      return this.moment(this.article.date).format("ll");
    },
  },
  methods: {
    cardClicked() {
      this.$emit("cardClicked");
    },
  },
};
</script>

<style scoped>
.news-orange-bar {
  margin: 0 auto;
  height: 10px;
  border-radius: 16px;
  width: 60%;
  margin-top: -5px;
  background: rgb(255, 211, 0);
  background: -moz-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd300",endColorstr="#ff7800",GradientType=1);
}
.img {
  width: 100%;
  padding-top: 56%;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
}
.news-card {
  border-radius: 16px;
  padding: 0.8rem;
  background: white;
  text-align: left;
  overflow: hidden;
  transition: 0.4s ease;
  height: 100%;
  margin-bottom: 1.5rem;
  font-family: "Avenir roman";
}
.news-card:hover {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}
.title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  font-family: "Lemon Milk";
  margin-bottom: 1rem;
}
.subtitle {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: rgb(255 183 0);
  margin-bottom: 1rem;
}
.info {
  margin-top: 1rem;
  padding: 0 0.5rem;
}
.article-info {
  font-size: 0.8rem;
  color: rgb(110, 110, 110);
  border-radius: 16px;
}
.article-info > span {
  margin-right: 10px;
  display: inline-block;
}
</style>
