import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import { BootstrapVue } from "bootstrap-vue";
import { ObserveVisibility } from "vue-observe-visibility";
import VueAnimateOnScroll from "vue-animate-onscroll";
import JQuery from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueScrollTo from "vue-scrollto";
import qs from "qs";
import moment from "moment";

// eslint-disable-next-line no-unused-vars
import NProgress from "nprogress";
window.$ = JQuery;
import Parallax from "vue-parallaxy";
// import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
// import BounceLoader from "vue-spinner/src/BounceLoader.vue";
import "viewerjs/dist/viewer.css";
import VueClazyLoad from "vue-clazy-load";

import VueViewer from "v-viewer";
Vue.use(VueClazyLoad);

Vue.directive("observe-visibility", ObserveVisibility);
Vue.use(VueViewer);
Vue.prototype.qs = qs;
Vue.prototype.moment = moment;
Vue.use(VueAxios, axios);
// Vue.component("bounce-loader", BounceLoader);
// Vue.component("sync-loader", SyncLoader);
Vue.component("clip-loader", ClipLoader);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueAnimateOnScroll);
Vue.component("parallax-img", Parallax);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -60,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  setTimeout(() => {
    NProgress.done();
  }, 500);
});
Vue.mixin({
  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        if (!to.meta) return false;
        let title = to.meta.title;
        if (to.params) {
          if (to.params.thisDept)
            title = `${to.params.thisDept.toUpperCase()} Department`;
          if (to.params.profileID) title = to.params.profileID.toUpperCase();
          if (to.params.title && to.name === "readNews")
            title = "News: " + to.params.title;
          if (to.params.title && to.name === "viewEvent")
            title = "Campus Event: " + to.params.title;
        }
        document.title =
          title + " - South Philippine Adventist College" ||
          "South Philippine Adventist College";
      },
    },
  },
  filters: {
    philippineCurrency(number) {
      if (number === null) return null;
      if (isNaN(parseFloat(number))) return null;
      number = parseFloat(number);
      return number.toLocaleString("en-US", {
        style: "currency",
        currency: "PHP",
        minimumFractionDigits: 2,
      });
    },
  },
  data() {
    return {
      backendServer: process.env.VUE_APP_BACKEND_SERVER,
    };
  },
  methods: {
    axiosErrorX(error) {
      let shouldTryAgain = false;
      console.log(error.message);
      let errorMsg = "Something went wrong.";
      if (
        error === "Error: Network Error" ||
        error.message === "Network Error"
      ) {
        this.$store.commit("setConnection", {
          connected: false,
        });
        errorMsg = "No internet or connection timed out.";
        shouldTryAgain = true;
      } else {
        const status =
          error.response && error.response.status
            ? error.response.status
            : null;
        switch (status) {
          case 422:
            if (error.response && error.response.data)
              this.popAlert("Error", error.response.data);
            break;
          case 408:
            errorMsg = "Connection timed out.";
            shouldTryAgain = true;
            break;
          case 503:
            errorMsg = "Server is under maintenance.";
            break;
          default:
            this.popAlert("Error", errorMsg);
            shouldTryAgain = true;
        }
      }
      return shouldTryAgain;
    },
    popAlert(title, msg) {
      console.log(title);
      console.log(msg);
    },
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
