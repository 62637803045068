<template>
  <div class="president-panel">
    <div class="row">
      <div class="col-md-7 msg-cont">
        <p
          class="msg"
          v-animate-onscroll="'animated fadeInUp'"
          data-animate-onscroll-offset="800px"
        >
          My warm greetings to all of you for choosing South Philippine
          Adventist College. <br />
          <br />
          Joining us here was a decision which do not only require to consider
          your resources--time, money and effort, but of what you will become
          after your studies. These elements demand complete thought and prayer.
          <br />
          <button
            class="link-btn-orange mt-5"
            @click="
              $router.push({
                name: 'adminProfile',
                params: { profileID: 'president' },
              })
            "
          >
            Meet the President
            <i class="icon-feather-arrow-right"></i>
          </button>
        </p>
      </div>
      <div class="col-md-5 text-left">
        <img
          v-animate-onscroll="'animated fadeInUp'"
          data-animate-onscroll-offset="800px"
          src="/img/pres1.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresidentPanel",
};
</script>

<style scoped>
.president-panel {
  background: #e6e8e4;
  overflow: hidden;
}
img {
  width: 80%;
  max-width: 776px;
  margin-top: 100px;
}
.msg {
  text-align: left;
  padding: 2rem;
  font-size: 1.3rem;
  font-family: "Avenir roman";
  width: 80%;
  color: #063077;
}
.msg-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 769px) {
  img {
    margin-top: 0px;
  }
  .msg {
    padding: 4rem 0rem;
  }
}
</style>
