<template>
  <div class="recent-news-widget-cont" v-if="news">
    <p class="theme-title mb-5">Latest News</p>
    <div class="row equal">
      <div
        class="col-12 col-sm-6 col-xl-3 item"
        v-for="(article, key) in latestNews"
        :key="key"
        @click="viewNews(article)"
      >
        <news-card :article="article" />
      </div>
    </div>
    <br />
    <button
      class="link-btn-orange mt-5"
      @click="
        $router.push({
          name: 'newsGallery',
        })
      "
    >
      More News
      <i class="icon-feather-arrow-right"></i>
    </button>
  </div>
</template>

<script>
import NewsCard from "../news/newsCard.vue";
export default {
  name: "RecentEventsWidget",
  components: { NewsCard },
  created() {
    this.getNews();
  },
  computed: {
    latestNews() {
      return this.sortedNews.slice(0, 4);
    },
    sortedNews() {
      const data = JSON.parse(JSON.stringify(this.news));
      return data.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    },
  },
  data() {
    return {
      news: null,
    };
  },
  methods: {
    viewNews(e) {
      this.$router.push({
        name: "readNews",
        params: { id: e.slug, title: e.title },
      });
    },
    getNews() {
      const query = this.qs.stringify(
        {
          sort: "date:desc",
          populate: {
            author: "*",
            cover: {
              fields: ["formats"],
            },
          },
          pagination: {
            limit: 4,
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );
      this.axios
        .request({
          method: "get",
          url: this.backendServer + "/api/articles?" + query,
          headers: {},
          data: {},
        })
        .then((response) => {
          this.news = response.data.data;
        })
        .catch((error) => {
          this.axiosErrorX(error);
        });
    },
  },
};
</script>

<style scoped>
.recent-news-widget-cont {
  padding: 2rem;
  background: #f8f9f9;
}
.row {
  max-width: 1600px;
  margin: 0 auto;
}
@media screen and (max-width: 769px) {
  .recent-news-widget-cont {
    padding: 1rem;
  }
}
</style>
