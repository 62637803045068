<template>
  <div class="recent-events-widget-cont" v-if="campus_events">
    <p class="theme-title mb-5">Recent Events</p>
    <div class="row equal">
      <div
        class="col-12 col-sm-6 col-xl-3 item"
        v-for="(event, key) in campus_events"
        :key="key"
        @click="viewEvent(event)"
      >
        <EventCard class="clickable" :event="event" layout="widget" />
      </div>
    </div>
    <br />
    <button
      class="link-btn-orange mt-5"
      @click="
        $router.push({
          name: 'campusEvents',
        })
      "
    >
      View all events
      <i class="icon-feather-arrow-right"></i>
    </button>
  </div>
</template>

<script>
import EventCard from "../campusLife/eventCard.vue";
export default {
  name: "RecentEventsWidget",
  components: { EventCard },
  created() {
    this.getEvents();
  },
  computed: {},
  data() {
    return {
      campus_events: null,
    };
  },
  methods: {
    viewEvent(e) {
      this.$router.push({
        name: "viewEvent",
        params: { id: e.slug, title: e.title },
      });
    },
    getEvents() {
      const query = this.qs.stringify(
        {
          sort: "date:desc",
          populate: {
            thumbnail: {
              fields: ["formats"],
            },
          },
          pagination: {
            limit: 4,
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );
      this.axios
        .request({
          method: "get",
          url: this.backendServer + "/api/campus-events?" + query,
          headers: {},
          data: {},
        })
        .then((response) => {
          this.campus_events = response.data.data;
        })
        .catch((error) => {
          this.axiosErrorX(error);
        });
    },
  },
};
</script>

<style scoped>
.recent-events-widget-cont {
  padding: 2rem;
}
.row {
  max-width: 1600px;
  margin: 0 auto;
}
@media screen and (max-width: 769px) {
  .recent-events-widget-cont {
    padding: 1rem;
  }
}
</style>
