<template>
  <div class="video-promo-cont">
    <div class="row">
      <div class="col-12 video-cont" v-if="main_feature">
        <div class="i-frame-wrapper">
          <iframe
            v-if="main_feature.source === 'facebook'"
            class="responsive-iframe"
            :src="main_feature.url"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>

          <iframe
            v-else
            class="responsive-iframe"
            :src="main_feature.url"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-md-3 col-12" v-for="(item, key) in dataSet" :key="key">
        <img :src="item.img" v-b-modal.modal-center @click="thisVideo = item" />
      </div>
    </div>
    <b-modal
      id="modal-center"
      size="xl"
      :hide-footer="true"
      centered
      v-if="thisVideo"
      :title="thisVideo.title"
    >
      <div class="container">
        <iframe
          class="responsive-iframe"
          :src="thisVideo.url"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "VideoPromotionPanel",
  data() {
    return {
      thisVideo: null,
      dataSet: [
        {
          img: "/img/maxresdefault.jpg",
          url: "https://www.youtube-nocookie.com/embed/PjM9HBatdQE",
          title: "Teaching Against all Odds",
        },
        {
          img: "/img/maxresdefault (1).jpg",
          url: "https://www.youtube-nocookie.com/embed/o7w85Z1--PY",
          title: "Learning is fun at SPAC",
        },
        {
          img: "/img/maxresdefault (2).jpg",
          url: "https://www.youtube-nocookie.com/embed/A6zIP3I9AMc",
          title: "Learning Against all Odds",
        },
        {
          img: "/img/maxresdefault (3).jpg",
          url: "https://www.youtube-nocookie.com/embed/nVMqhNuIH3U",
          title: "Timeless Holistic Excellence",
        },
      ],
      main_feature: {
        url: "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FSPACAcademy%2Fvideos%2F474868548600572%2F&show_text=false&width=560&t=0",
        source: "facebook",
      },
    };
  },
  methods: {
    showModal() {
      this.$root.$emit("bv::show::modal", "modal-1", "#btnShow");
    },
  },
};
</script>

<style scoped>
.video-cont {
  margin-bottom: 1rem;
}
.video-promo-cont {
  padding: 5%;
  position: relative;
}
img {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 20px;
  transition: 0.2s ease;
  cursor: pointer !important;
}
img:hover {
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}
.container,
.i-frame-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.i-frame-wrapper {
  margin-bottom: 100px;
  margin: 0 auto;
  display: block;
  border-radius: 16px;
  max-width: 1600px;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
