<template>
  <div class="home">
    <splash-screen v-if="showSplashScreen" />

    <Panel1 />
    <panel-2 />
    <!-- <HomeFeatures /> -->
    <video-promotion />
    <accredits />
    <PresidentPanel />
  </div>
</template>

<script>
// @ is an alias to /src
import Panel1 from "@/components/home/panel1.vue";
import Panel2 from "@/components/home/panel2.vue";
// import HomeFeatures from "@/components/home/features.vue";
import PresidentPanel from "@/components/home/president.vue";
import Accredits from "@/components/home/accredits.vue";
import VideoPromotion from "@/components/home/videoPromotion.vue";
import SplashScreen from "@/components/splashScreen.vue";
export default {
  name: "HomeView",
  components: {
    Panel1,
    Panel2,
    // HomeFeatures,
    PresidentPanel,
    Accredits,
    VideoPromotion,
    SplashScreen,
  },
  mounted() {
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 9000);
  },
  data() {
    return {
      showSplashScreen: true,
    };
  },
};
</script>
